import React from 'react';
import styles from './Button.module.scss';
import PropTypes from 'prop-types';

const Button = (props) => {

  const classes = (props.secondary) ? `${styles.btn} ${styles.btn_secondary}` : `${styles.btn} ${styles.btn_primary}`
  const target = props.target

  if( target ){
    return (
      <a className={classes} href={target} target="_blank"><span>{props.label}</span></a>
    );
  }

  return (
    <button className={classes} target={target}><span>{props.label}</span></button>
  );
};

Button.propTypes = {
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  target: PropTypes.string
}

export default Button;
