import React from 'react';

const Arrow = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="audi-down-small" viewBox="0 0 24 24" version="1.1">
            <polyline transform="translate(11.794000, 12.273500) rotate(90.000000) translate(-11.794000, -12.273500) " points="9.2245 18.0675 8.5205 17.3575 13.6475 12.2705 8.5255 7.1895 9.2295 6.4795 15.0675 12.2705 9.2245 18.0675"></polyline>
        </svg>
    );
}

export default Arrow;
