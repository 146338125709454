import React, { useState, useEffect } from 'react';
import Stage from "./Stage";
import Main from "./Main";
import { useQueryParam, StringParam } from "use-query-params";
import useResizeObserver from '../hooks/useResizeObserver';
import useResponsiveHandler from '../hooks/useResizeHandler';


const App = () => {
  const languageParam = useQueryParam("lang", StringParam)[0] || "en-en";

  const [content , setContent ] = useState(null);
  const [vehicles, setVehicles] = useState(null);
  const [ref, entry] = useResizeObserver();
  useResponsiveHandler(entry);

  useEffect(() => {
    // get content
    fetch(process.env.GATSBY_API_CONNECT_PATH + "/api/singletons/get/AudiCompatibilityCheck?token=" + process.env.GATSBY_API_CONNECT_KEY + "&lang=" + languageParam)
      .then(response => response.json())
      .then(content => {
        setContent(content);
      })
      .catch(error => console.error(error))
    // get vehicle list
    fetch(process.env.GATSBY_API_CONNECT_PATH + "/api/collections/get/AudiVehicleData?token=" + process.env.GATSBY_API_CONNECT_KEY + "&populate=1")
      .then(response => response.json())
      .then(vehicles => {
        setVehicles(vehicles.entries);
      })
      .catch(error => console.error(error))

  }, [languageParam]);

  if (content) {
    document.title = content.app_title || ""
    const metaDescription = content.header_description || ""
    //document.querySelector('meta[name="description"]').setAttribute("content", metaDescription)
    document.documentElement.lang = languageParam.replace(/^(.*?)-/,"") || "en"
  }

  return (
    <div ref={ref}>
    {content &&
      <div>
          <Stage stage_image={content.stage_image} stage_claim={content.stage_claim} stage_headline={content.stage_headline} stage_image_disclaimer={content.stage_image_disclaimer} />
          <Main content={content} vehicles={vehicles}/>
      </div>
    }{!content &&
      <div>
          <Stage stage_claim={""} stage_headline={""}/>
          <div>loading content ... </div>
      </div>
    }
    </div>
  );
}

export default App;