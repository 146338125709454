import React from 'react';
import styles from './Result.module.scss';
import Button from '../Button';

const Result = props => {
  const content  = props.content
  let showResult = props.result
  const state    = props.state
  const vehicles = props.vehicles
  const name     = state.selection[0].selected
  
  // default image?
  // image from static (lower case car name as image name)
   let imagePath = process.env.GATSBY_APP_PATH_PREFIX +"image/cars/" + name.replace(/\s+/g, '_').toLowerCase() + ".png"
  // get image from cockpit IF it was uploaded
  vehicles.forEach(car => {
    if ((car.name === state.selection[0].selected) && (car.year === state.selection[1].selected) ) {
      if (car.image !== null && car.image.path) {
        imagePath = process.env.GATSBY_API_CONNECT_PATH + "/storage/uploads" + car.image.path
      }
    }
  })

  let headline, copy, primary_label, primary_target, secondary_label, secondary_target
  headline = content.result_0_headline

  // Result Modifier:
  // The default result is based on model <=> model year dependency (Cockpit Collection "AudiVehicleData")
  // The result is written to the state (false/0/1/2) by the Dropdown handling
  // Here, the result is modified by
  //   A) availability of Connect in the country (content.connect_available = true/false)
  //   B) engine/drive type (could be subject to change in the future!)
  if (!content.connect_available && showResult === 2) {
    // not a connect country, "maybe available" becomes "available"
    showResult = 1
  }
  let selectedEngine = state.selection[2].selected
  const exceptionList = ["gtron", "retrofitting"] // could become maintainable in the CMS.
  if (exceptionList.indexOf(selectedEngine) !== -1) {
    // selected engine is on the exception list, result is "not compatible"
    showResult = 0
  }

  switch(showResult) {
    case 0:
      headline         = content.result_0_headline
      copy             = content.result_0_copytext || ""
      primary_label    = content.result_0_button_primary_label    || ""
      primary_target   = content.result_0_button_primary_target   || ""
      secondary_label  = content.result_0_button_secondary_label  || ""
      secondary_target = content.result_0_button_secondary_target || ""
      break;
    case 1:
      headline         = content.result_1_headline
      copy             = content.result_1_copytext || ""
      primary_label    = content.result_1_button_primary_label    || ""
      primary_target   = content.result_1_button_primary_target   || ""
      secondary_label  = content.result_1_button_secondary_label  || ""
      secondary_target = content.result_1_button_secondary_target || ""
      break;
    case 2:
      headline         = content.result_2_headline
      copy             = content.result_2_copytext || ""
      primary_label    = content.result_2_button_primary_label    || ""
      primary_target   = content.result_2_button_primary_target   || ""
      secondary_label  = content.result_2_button_secondary_label  || ""
      secondary_target = content.result_2_button_secondary_target || ""
      break;
    default:
      console.log("No result ID")
      break;
  }

  // replace the model placeholder in the headline
  headline = headline.replace("[model]", state.selection[0].selected)

  // show only buttons that have a target assigned
  const primary = primary_target.length > 0 ? true : false;
  const secondary = secondary_target.length > 0 ? true : false;

  return (
    <div className={styles.result}>
      
        <h4 className={styles.result__headline}>{headline}</h4>

        {copy.length > 0 &&
        <div className={styles.result__textcontainer}>
          <p dangerouslySetInnerHTML={{__html: copy}}></p>
        </div>
        }

        <div className={styles.result__buttoncontainer}>
          {primary &&
          <Button primary label={primary_label} target={primary_target} />
          }
          {secondary &&
          <Button secondary label={secondary_label} target={secondary_target} />
          }
        </div>
        
        <div className={styles.result__imagecontainer}>
          <img className={styles.result__image} src={imagePath} alt={name} />;
          {content.result_image_disclaimer &&
            <p className={styles.result__imagedisclaimer}>
              {content.result_image_disclaimer}
            </p>
          }
        </div>
          

    </div>

  );
};

export default Result;
