import React, { useEffect, useRef } from 'react';
import styles from './Dropdownlist.module.scss';
import Close from '../../images/Close.jsx';

// Helper function to move an array item
const array_move = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
          arr.push(undefined);
      }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

const Dropdownlist = (props) => {
  const handleClick  = props.handleClick
  const handleSelect = props.handleSelect
  const state        = props.state
  const content      = props.content
  const dropId       = props.dropId
  const list         = props.list
  const ref          = useRef(null)

  let dropdopen   = state.selection[dropId].dropdown
  const listclasses = dropdopen ? `${styles.dropdownlist} ${styles.dropdownlist__show}` : `${styles.dropdownlist}`

  useEffect(() => {
    document.addEventListener("click", handleClick())
    return() => {
      document.removeEventListener("click", handleClick())
    }
  }, [handleClick])

  const translateName = (name) => {
    if (dropId === 1) {
      let t_until  = content.year_until+" " || "< "
      let t_from   = content.year_from +" " || "> "
      let t_all    = content.year_all       || "all"
      let t_stroke = content.year_stroke    || "-"
      return name.replace("<",t_until).replace(">",t_from).replace("all",t_all).replace("-",t_stroke)
    }
    else if (dropId === 2) {
      return content["engine_" + name] ? content["engine_" + name] : name
    }
    else {
      return name
    }
  }

  // make sure the year list is sorted
  if (dropId === 1) {
    list.sort();
    // will usually put "<0000" at the second to last place and ">0000" at the last place
    // move "<0000" to the first place and make sure, ">0000" is at the last place
    for (let i = 0; i < list.length; i++) {
      if (list[i].includes("<")) {
        array_move(list, i, 0)
      }
      else if (list[i].includes(">")) {
        array_move(list, i, list.length-1)
      }
    }
  }

  return (
      <div ref={ref} className={listclasses}>
        {/*<div className={styles.dropdownlist__label}>
          <span>{props.name}</span>
          <div className={styles.dropdownlist__close} role={"button"} tabIndex={0} onClick={() => { handleClick() }} onKeyDown={() => { handleClick() }}>
            <Close/>
          </div>
        </div>*/}
        {list.map((item, i) => (
          <div className={styles.dropdownlist__item} key={i} value={i} role={"button"} tabIndex={0} onClick={(e) => { handleSelect(e._targetInst.key) }} onKeyDown={(e) => { handleSelect(e.target.value) }} >
            {translateName(item)}
          </div>
        ))}
      </div>
  );
};

export default Dropdownlist;
