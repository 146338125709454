import React from 'react';
import styles from './Dropdown.module.scss';
import Dropdownlist from '../Dropdownlist/Dropdownlist';
import Arrow from '../../images/Arrow.jsx';

const Dropdown = (props) => {
  const state       = props.state
  const actions     = props.actions
  const vehicles    = props.vehicles
  const content     = props.content
  const dropId      = props.dropId
    let list        = state.selection[dropId].list
    let clickable   = (dropId <= state.selectionProgress) ? true : false
    let dropdopen   = state.selection[dropId].dropdown
  const dropclasses = clickable ? `${styles.dropdown__container} ${styles.dropdown__container_active}` : `${styles.dropdown__container}`
  const nameclasses = dropdopen ? `${styles.dropdown__selected} ${styles.dropdown__selected_open}` : `${styles.dropdown__selected}`

  const translateName = (name) => {
    if (dropId === 1) {
      let t_until  = content.year_until+" " || "< "
      let t_from   = content.year_from +" " || "> "
      let t_all    = content.year_all       || "all"
      let t_stroke = content.year_stroke    || "-"
      return name.replace("<",t_until).replace(">",t_from).replace("all",t_all).replace("-",t_stroke)
    }
    else if (dropId === 2) {
      return content["engine_" + name] ? content["engine_" + name] : name
    }
    else {
      return name
    }
  }
  
  let name = state.selection[dropId].selected ? translateName(state.selection[dropId].selected) : props.name

  const handleClick = () => {
    // toggle dropdown list
    actions.toggleDropdownList(dropId, !state.selection[dropId].dropdown);
  }

  const handleSelect = (key) => {
    // change dropdown name ONLY if the key changed!
    if (name !== list[key]) {
      name = list[key];
      // make dropdown selection
      actions.setDropdownSelection(dropId, key, name);
      // handle next step
      handleAction(dropId + 1);
    }
    // close dropdown list
    actions.toggleDropdownList(dropId, !state.selection[dropId].dropdown);
  }

  const handleAction = (action) => {
    if (action === 1) {
      // build available model year list
      let years = []
      vehicles.forEach(car => {
        if (car.name === name) {
          years.push(car.year)
        }
      })
      actions.setList(dropId, years)
      actions.setDropdownSelection(1, false, false)
      actions.setDropdownSelection(2, false, false)
      actions.setResult(false)
    }
    if (action === 2) {
      // build available engine options list
      let engines = []
      vehicles.forEach(car => {
        if ((car.name === state.selection[0].selected) && (car.year === name) ) {
          car.engine_select.forEach(engine => {
            engines.push(engine)
          })
        }
      })
      actions.setList(dropId, engines)
      actions.setDropdownSelection(2, false, false)
      actions.setResult(false)
    }
    if (action === 3) {
      // calculate result
      let result = ""
      vehicles.forEach(car => {
        if ((car.name === state.selection[0].selected) && (car.year === state.selection[1].selected) ) {
          switch(car.compatibility) {
            case "not compatible":
              result = 0;
              break;
            case "compatible":
              result = 1;
              break;
            case "maybe":
              result = 2;
              break;
            default:
              result = 0;
              break;
          }
        }
      })
      actions.setResult(result)
    }
    // next dropdown available
    actions.setProgress(dropId + 1);
  }

  return (
    <div className={dropclasses} >
      <div className={nameclasses} role={"button"} tabIndex={dropId} onClick={clickable ? () => { handleClick() } : undefined } onKeyDown={clickable ? () => { handleClick() } : undefined } >
        <span>{name}</span>
        <div className={styles.dropdown__arrow} >
            <Arrow/>
          </div>
      </div>
      {dropdopen &&
        <Dropdownlist state={state} content={content} dropId={dropId} name={props.name} list={list} handleClick={() => handleClick} handleSelect={(key) => handleSelect(key)} />
      }
    </div>
  );
};

export default Dropdown;
