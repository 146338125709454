import React, { useState, useMemo, useContext } from 'react';

const AppContext = React.createContext({});

const useAppState = () => {
  const initialState = {
    selectionProgress: 0,
    selection: [
      {
        name: "model",
        list: [],
        dropdown: false,
        key: false,
        selected: false
      },
      {
        name: "year",
        list: [],
        dropdown: false,
        key: false,
        selected: false
      },
      {
        name: "engine",
        list: [],
        dropdown: false,
        key: false,
        selected: false
      }
    ],
    result: false
  };
  const [state, setState] = useState(initialState);
  const actions = useMemo(() => getActions(setState), [setState]);

  return { state, actions };
};

const getActions = (setState) => ({
  setProgress: (newProgress) => {
    setState(state => {
      return ({...state, selectionProgress: newProgress });
    });
  },

  toggleDropdownList: (dropId, change) => {
    setState(state => {
      const selection = state.selection;
      selection[dropId].dropdown = change;
      return ({...state, selection: selection});
    });
  },

  setDropdownSelection: (dropId, key, name) => {
    setState(state => {
      const selection = state.selection;
      selection[dropId].key = key;
      selection[dropId].selected = name;
      return ({...state, selection: selection});
    });
  },

  setList: (dropId, list) => {
    setState(state => {
      const selection = state.selection;
      selection[dropId+1].list = list;
      return ({...state, selection: selection});
    });
  },

  toggleResult: () => {
    setState(state => {
      if (state.result) {
        return ({...state, result: false});
      } else {
        return ({...state, result: true});
      }
    });
  },

  setResult: (result) => {
    setState(state => {
      return ({...state, result: result});
    });
  }
});

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppContext, useAppState, useAppContext };
