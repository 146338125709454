import React, { useEffect } from 'react';
import { useAppState } from '../../hooks/useAppState';
import styles from './Main.module.scss';
import Dropdown from '../Dropdown';
import Result from '../Result';

const Main = props => {
  const { state, actions } = useAppState()
  const content = props.content
  const vehicles = props.vehicles || []

  useEffect(() => {
    if (vehicles && vehicles !== null && vehicles.length > 0) {
      let list = []
      vehicles.forEach(car => {
        if (list.includes(car.name)) {
          // nothing
        }
        else {
          list.push(car.name)
        }
      })
      actions.setList(-1, list)
    }
  },
  [vehicles, actions]);

  return (
    <section className={styles.main}>
      <h3>{content.check_headline}</h3>

      <div className={styles.main__selectionwrapper}>
          <Dropdown state={state} actions={actions} content={content} vehicles={vehicles} dropId={0} name={content.dropdown_1_label} />
          <Dropdown state={state} actions={actions} content={content} vehicles={vehicles} dropId={1} name={content.dropdown_2_label} />
          <Dropdown state={state} actions={actions} content={content} vehicles={vehicles} dropId={2} name={content.dropdown_3_label} />
      </div>

      {state.result !== false &&
        <Result state={state} actions={actions} content={content} vehicles={vehicles} result={state.result} />
      }

    </section>
  )
}

export default Main;
