import React from 'react';
import styles from './Stage.module.scss';
import stageImage from '../../images/headerimage.jpg';



const Stage = props => {

  let imagePath = props.stage_image && props.stage_image.path ? process.env.GATSBY_API_CONNECT_PATH + "/storage/uploads" + props.stage_image.path : stageImage

  let backgroundImage = { 
    backgroundImage: `linear-gradient( to bottom, rgba(1,1,1,0.2), rgba(2,2,2,0.2) ), url(${imagePath})` 
  }

  return (
    <section className={styles.stage} style={backgroundImage}>
      <p className={styles.stage__claim} dangerouslySetInnerHTML={{__html: props.stage_claim}}>
      </p>
      <div className={styles.stage__bottom}>
        <h1 className={styles.stage__headline}>{props.stage_headline}</h1>
      </div>
      
      {props.stage_image_disclaimer &&
        <p className={styles.stage__imagedisclaimer}>
          {props.stage_image_disclaimer}
        </p>
      }
    </section>
  );
};

export default Stage;
