import { useLayoutEffect } from 'react';

const useResizeHandler = (entry, actions) => {

  useLayoutEffect(() => {
    (entry).contentRect && console.log((entry).contentRect['height']);
    return (entry).contentRect && 
      window.parent.postMessage({"height": (entry).contentRect['height'], "url": window.location.href}, "*"); 
  }, [entry])
}

export default useResizeHandler;